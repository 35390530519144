import React, { ChangeEvent, Component, FormEvent } from 'react';
import classNames from 'classnames';
import loader from './Ellipsis-1s-200px.gif';
// import Logo from './Logo.png';
import './App.scss';
import AIService from './services/AIService';
import { gapi } from 'gapi-script';

const AUTO_RECOMMEND_TIMEOUT = 24 * 60 * 60 * 1000;
// const AUTO_RECOMMEND_TIMEOUT = 60 * 60 * 1000;

interface Dictionary<T> {
  [key: string]: T,
}

interface AppProps {

}

interface AppState {
  chatText: string,
  chatItems: Array<any>,
  currentThreadIndex: number,
  threadIds: Array<string>,
  isLoading: boolean,
  isLoadingTasks: boolean,
  isSignedIn: boolean,
  isDebug: boolean,
  isRecommending: boolean,
  isAutoRecommending: boolean,
  autoRecommendTimeout: NodeJS.Timeout | null,
  lastAutoRecommend: Date | null,
  taskLists: Array<any>,
  tasks: Dictionary<Array<any>>,
  currentTaskList: number,
  googleUser: gapi.auth2.GoogleUser | null,
  recommendations: Array<string>,
}

class App extends Component<AppProps, AppState> {

  didInit = false;

  state = {
    chatText: "",
    chatItems: [],
    currentThreadIndex: 0,
    threadIds: JSON.parse(localStorage.getItem("ThreadIds") || "[]"),
    isLoading: true,
    isLoadingTasks: false,
    isSignedIn: false,
    isDebug: false,
    isRecommending: false,
    isAutoRecommending: false,
    autoRecommendTimeout: null,
    lastAutoRecommend: null,
    taskLists: [],
    tasks: {},
    currentTaskList: -1,
    googleUser: null,
    recommendations: [],
  }

  componentDidMount() {
    if(!this.didInit) {
      const threadId = this.getCurrentThreadId();

      if(!threadId) {
        this.createAndSelectNewThread();
      }
      else {
        this.refreshChat();
      }
      
      gapi.load("client:auth2", () => {
        gapi.client
        .init({
          apiKey: process.env.REACT_APP_GOOGLE_API_KEY,
          clientId: process.env.REACT_APP_GOOGLE_CLIENT_ID,
          discoveryDocs: ["https://www.googleapis.com/discovery/v1/apis/tasks/v1/rest"],
          scope: "https://www.googleapis.com/auth/tasks",
        })
        .then(() => {
          // Listen for sign-in state changes.
          const isSignedIn = gapi.auth2.getAuthInstance().isSignedIn.get();
          if (isSignedIn) {
            const googleUser = gapi.auth2.getAuthInstance().currentUser.get();
            this.setState({ isSignedIn, googleUser });
            this.loadTaskLists();
          }

          gapi.auth2
            .getAuthInstance()
            .isSignedIn.listen((signedIn: boolean) => {
              this.setState({ isSignedIn: signedIn });
            });
        })
        .catch((err: any) => {
          console.error("Caught error", err);
        });
      });
    }

    this.didInit = true;
  }

  signInOrOut() {
    const { isSignedIn } = this.state;
    if (isSignedIn) {
      gapi.auth2.getAuthInstance().signOut()
        .then(() => {
          this.setState({
            isSignedIn: false,
          });
        })
        .catch((error: any) => {
          console.log("error signing out?", error);
        });
    }
    else {
      gapi.auth2.getAuthInstance().signIn()
        .then((googleUser) => {
          this.setState({
            isSignedIn: true,
            googleUser,
          });

          this.loadTaskLists();
        })
        .catch((error: any) => {
          console.log("error singing in?", error);
        });
    }
  }

  loadTaskLists() {
    gapi.client.request({ path: "https://www.googleapis.com/tasks/v1/users/@me/lists" })
      .then(async (response: any) => {
        const taskLists = response.result.items;
        this.setState({
          taskLists,
        });
      })
      .catch((error: any) => {
        console.log('error getting task lists', error);
      });
  }

  loadTasks(taskListIndex: number, reload: boolean = false, recommend: boolean = false) {
    const taskLists: Array<any> = this.state.taskLists;
    const tasks: Dictionary<Array<any>> = this.state.tasks;
    const taskListId = taskLists[taskListIndex].id;

    if (!tasks[taskListId] || reload) {
      // var completedMinDate = new Date(new Date().setDate(new Date().getDate() - 7)).toISOString();
      // var updatedMin = new Date(new Date().setDate(new Date().getDate() + 180)).toISOString();
      gapi.client.request({ path: "https://www.googleapis.com/tasks/v1/lists/" + taskListId + '/tasks?maxResults=100&showHidden=true' })
        .then((response: any) => {
          const { tasks } = this.state;
          let newTasks: Dictionary<Array<any>> = { ...tasks };
          newTasks[taskListId] = response.result.items;
          this.setState({ tasks: newTasks }, () => {
            if (recommend) {
              this.recommend();
            }
          });
        })
        .catch((error: any) => {
          console.log('error getting tasks for list ' + taskListId, error);
        });
    }

    this.setState({ currentTaskList: taskListIndex });
  }
  
  createAndSelectNewThread(ev: any = null) {
    ev?.preventDefault();

    AIService.startThread().then((newThread: any) => {
      const newThreadId = newThread.id;
      const { threadIds } = this.state;
      let newThreadIds = threadIds ? threadIds : [];
      newThreadIds.push(newThreadId);

      localStorage.setItem('ThreadIds', JSON.stringify(newThreadIds));
    
      this.setState({
        threadIds: newThreadIds,
        currentThreadIndex: newThreadIds.length - 1,
        isLoading: false,
      });
    });
  }

  switchThread(ev: any, newThreadIndex: number) {
    ev.preventDefault();

    const {threadIds } = this.state;

    if (threadIds && threadIds.length > newThreadIndex) {
      this.setState({
        currentThreadIndex: newThreadIndex,
      }, () => {
        this.refreshChat();
      });
    }
    else {
      console.log('Unexpected error trying to switch to thread:', newThreadIndex);
    }
  }

  getCurrentThreadId() {
    const { threadIds, currentThreadIndex } = this.state;

    if (threadIds && threadIds.length > currentThreadIndex) {
      return threadIds[currentThreadIndex];
    }
    else {
      return null;
    }
  }

  updateChatText(ev: ChangeEvent<HTMLInputElement>) {
    this.setState({
      chatText: ev.target.value,
    });
  }

  sendChat(ev: FormEvent<HTMLFormElement>) {
    ev.preventDefault();
    const { chatText } = this.state;
    this.sendChatText(chatText);
  }

  sendChatText(chatText: string) {
    const { chatItems } = this.state;

    const threadId = this.getCurrentThreadId();

    let textToSend = chatText;

    if(threadId) {
      AIService.sendChat(threadId, textToSend).then(() => {
        AIService.startRun(threadId).then(() => {
          this.refreshChat();
        });
      });

      this.setState({
        chatItems: [...chatItems, { role: 'user', text: textToSend }],
        chatText: "",
        isLoading: true,
      });
    }
    else {
      console.log('Error: no thread id');
    }
  }

  forceRun() {
    const threadId = this.getCurrentThreadId();
    AIService.startRun(threadId).then(() => {
      this.refreshChat();
    });
  }

  // appendOptionsToChatText(chatText: string, options: Array<SelectableItem>, baseText: string): string {
  //   let ret = baseText;
  //   let delimiter = "";
  //   options.forEach((item: SelectableItem) => {
  //     if(item.selected) {
  //       ret += delimiter + item.value;
  //       delimiter = " and ";
  //     }
  //   });
  //   ret += ".";
  //   return ret;
  // }

  refreshChat() {
    this.refreshChatSafe(this);
  }

  refreshChatSafe(thisRef: any) {
    const threadId = thisRef.getCurrentThreadId();
    const { isRecommending } = thisRef.state;

    if(threadId) {
      thisRef.setState({ isLoading: true });
      AIService.getThread(threadId).then((rawChat: any) => {
        let chatItems: Array<any> = [];
        let isLoading = false;
        let recommendations: Array<string> = [];

        if (rawChat.data && rawChat.data.length) {
          rawChat.data.reverse().forEach((chatItem: any) => {
            
            const hasContent = chatItem.content && chatItem.content.length;
            let textObj = hasContent ? chatItem.content[0].text : null;

            if (
              (chatItem.role === 'assistant' || 
              chatItem.role === 'user') &&
              textObj &&
              textObj.value
            ) {
              // let parsedText = textObj.value.replaceAll("\\n", "<br />");
              let parsedText = textObj.value;
              const reggie = new RegExp(/([0-9]+\. \*\*.(?:.|\n)+?(?:\.))/g);
              const matches = parsedText.match(reggie);
              if (matches) {
                recommendations = [];
                matches.forEach((match: string) => {
                  let parsedMatch = match.replace("**", "<strong>");
                  parsedMatch = parsedMatch.replace("**", "</strong>");
                  parsedText = parsedText.replace(match, `<div class="recommended-task">${parsedMatch}</div>`);
                  recommendations.push(parsedMatch);
                });
              }
              chatItems.push({ role: chatItem.role, text: parsedText });
            }
            
            if (
              chatItem.id === rawChat.first_id && 
              (
                chatItem.role === 'user' ||
                (
                  chatItem.role === 'assistant' &&
                  (
                    !textObj || 
                    textObj.value === ''
                  )
                )
              )
            ) {
              isLoading = true;
            }
          });
        }
        
        thisRef.setState({ chatItems, isLoading: isLoading });

        if (isLoading) {
          setTimeout(() => {
            thisRef.refreshChatSafe(thisRef);
          }, 1000)
        }
        else if(isRecommending) {
          // console.log(recommendations);
          // recommendations.map(async (recommendation: string) => {
          //   var tempElement = document.createElement('div');
          //   tempElement.innerHTML = recommendation;
            
          //   await thisRef.createTask(tempElement.innerText);
          // });

          this.setState({ isRecommending: false, recommendations });

          // const { currentTaskList } = this.state;
          // this.loadTasks(currentTaskList, true);
        }
      });
    }
    else {
      console.log('Error: no thread id');
    }
  }

  recommend() {
    const { currentTaskList } = this.state;
    if(currentTaskList < 0) return;
    const taskLists: Array<any> = this.state.taskLists;
    const tasks: Dictionary<Array<any>> = this.state.tasks;
    const taskList = taskLists[currentTaskList];
    
    if(!tasks[taskList.id]) {
      console.log('Unexpected error occurred.');
      return;
    }

    const allTasks = tasks[taskList.id];
    let completedTasks: Array<any> = [];
    let incompleteTasks: Array<any> = [];
    allTasks.map((task: any) => {
      if(!task) return;
      if(task.status === "completed") {
        completedTasks.push(task);
      } else {
        incompleteTasks.push(task);
      }
    });

    let chatText = `In my task list titled "${taskList.title}", here are my completed tasks: ` + "\n";

    completedTasks.map((task: any, index: number) => {
      if(task) chatText += `${index + 1}. ${task.title}` + "\n";
    });

    chatText += "And here are my incomplete tasks:" + "\n";

    incompleteTasks.map((task: any, index: number) => {
      if(task) chatText += `${index + 1}. ${task.title}` + "\n";
    });

    chatText += "Based on those lists, could you recommend some new tasks for me?";

    this.setState({ isRecommending: true });
    this.sendChatText(chatText);    
  }

  startAutoRecommend() {
    this.setState({
      isAutoRecommending: true,
    });

    this.autoRecommend(this);
  }

  autoRecommend(thisRef: any) {
    const { recommendations, currentTaskList } = thisRef.state;

    recommendations.map(async (recommendation: string) => {
      var tempElement = document.createElement('div');
      tempElement.innerHTML = recommendation;
      
      await thisRef.createTask(tempElement.innerText);
    });

    thisRef.loadTasks(currentTaskList, true);

    var nextTimeout = setTimeout(() => {
      thisRef.autoRecommend(thisRef);
    }, AUTO_RECOMMEND_TIMEOUT);

    thisRef.setState({ 
      autoRecommendTimeout: nextTimeout,
      lastAutoRecommend: new Date(),
    });

    thisRef.recommend();
  }

  handleTaskClick(ev: React.MouseEvent) {
    let evTarget = ev.target as HTMLElement;
    this.createTask(evTarget.innerText);
  }

  createTask(taskText: string) {
    const { currentTaskList } = this.state;

    if (currentTaskList < 0) {
      alert("Please select a task list.");
      return;
    }

    const taskLists: Array<any> = this.state.taskLists;
    const taskListId = taskLists[currentTaskList].id;

    const task = {
      title: taskText,
      notes: "",
      status: "needsAction",
    };

    gapi.client.request({ 
      path: "https://www.googleapis.com/tasks/v1/lists/" + taskListId + '/tasks', 
      method: 'POST', 
      body: task
    })
      .then(() => {
        this.loadTasks(currentTaskList, true);
      })
      .catch((error: any) => {
        console.log('error pushing task for list ' + taskListId, error);
      });
  }

  async createTaskAsync(taskText: string) {
    const { currentTaskList } = this.state;

    if (currentTaskList < 0) {
      alert("Please select a task list.");
      return;
    }

    const taskLists: Array<any> = this.state.taskLists;
    const taskListId = taskLists[currentTaskList].id;

    const task = {
      title: taskText,
      notes: "",
      status: "needsAction",
    };

    return gapi.client.request({ 
      path: "https://www.googleapis.com/tasks/v1/lists/" + taskListId + '/tasks', 
      method: 'POST', 
      body: task
    });
  }

  selectTaskList(taskListIndex: number) {
    if (this.state.isRecommending) return;
    this.loadTasks(taskListIndex, false, true);
  }
  
  render() {

    const { 
      chatItems, 
      chatText, 
      isLoading,
      threadIds,
      currentThreadIndex,
      isLoadingTasks,
      isSignedIn,
      taskLists,
      currentTaskList,
      isDebug,
      googleUser,
      isRecommending,
      isAutoRecommending,
    } = this.state;

    const tasks: Dictionary<Array<any>> = this.state.tasks;
    const selectedTaskList: any = taskLists && currentTaskList >= 0 ? taskLists[currentTaskList] : null;
    const recommendations: Array<string> = this.state.recommendations;
    const lastAutoRecommend: Date | null = this.state.lastAutoRecommend;

    const loadingClasses = classNames(
      "loader",
      {
        "hidden": !isLoading
      }
    );

    return (
      <div className="app">
        <div className="header">
          <div className="logo-container">
            {/* <img className="logo" alt="Apollo Art" src={Logo} /> */}
            <span>TaskAI</span>
          </div>
          <div className="right">
            <button className="secondary" onClick={() => this.setState({isDebug: !isDebug})}>{ ' ' }</button>
          </div>
        </div>
        <div className="workflow-container">
          <div className="steps">
            <div className="step step-1">
              <h2>Step 1: Sign in with Google</h2>
              {isSignedIn ? <p>
                Signed in as {googleUser ? (googleUser as gapi.auth2.GoogleUser).getBasicProfile().getName() : "anonymous"} (<a href="$" onClick={() => this.signInOrOut()}>Sign out</a>)  
              </p>
              : <p>
                <button className="primary" onClick={() => this.signInOrOut()}>Sign in</button>
              </p>}
              
              {/* <button className={isSignedIn ? "secondary" : "primary"} onClick={() => this.signInOrOut()}>{isSignedIn ? "Sign out" : "Sign in"}</button> */}
            </div>
            <div className={"step step-2" + (isSignedIn ? "" : " hidden")}>
              <h2>Step 2: Choose your task list</h2>
              {!isLoadingTasks &&
              <div className="task-lists">
                {taskLists && taskLists.map((taskList: any, i: number) => 
                  <button key={i} className={"task-list secondary" + (currentTaskList === i ? " selected" : "")} disabled={isRecommending} onClick={() => this.selectTaskList(i)}>
                    {taskList.title}
                  </button>
                )}
              </div>
              }
            </div>
            <div className={"step step-3" + ((isRecommending || (recommendations && recommendations.length > 0)) ? "" : " hidden")}>
              <h2>Step 3: Add some tasks</h2>
              <div>
                <button disabled={isAutoRecommending || !(recommendations && recommendations.length > 0)} className="primary" onClick={() => this.startAutoRecommend()}>Auto-recommend</button>
                {isAutoRecommending && lastAutoRecommend && <div>
                  Last auto recommended at {new Date(lastAutoRecommend).toLocaleString()}
                  <br />
                  Will auto recommend at {new Date(new Date(lastAutoRecommend).getTime() + AUTO_RECOMMEND_TIMEOUT).toLocaleString()}
                </div>}
              </div>
              {!isRecommending && recommendations && recommendations.map((reco, index) =>
                <button disabled={isAutoRecommending} className="recommended-task secondary" key={index} dangerouslySetInnerHTML={{ __html: reco }} onClick={(e) => this.handleTaskClick(e)}></button>
              )}
              {isRecommending && 
                <div className="loader">
                  <img alt="Loading..." src={loader} />
                </div>
              }
            </div>
          </div>
          <div className="tasks-window-new">
            {selectedTaskList && <React.Fragment>
              <h2>Current Tasks in {selectedTaskList.title}</h2>
              {!isLoadingTasks && <div className="tasks">
                <table>
                  <thead>
                    <tr key={0}>
                      <th>Title</th>
                      <th>Status</th>
                      <th>Updated</th>
                    </tr>
                  </thead>
                  <tbody>
                    {tasks[selectedTaskList.id] && tasks[selectedTaskList.id].map((task: any, index: number) => <tr key={index + 1}>
                      <td>{task.title}</td>
                      <td>{task.status}</td>
                      <td>{new Date(task.updated).toISOString().replace("T", " ").replace("Z", "")}</td>
                    </tr>)}
                  </tbody>
                </table>
              </div>}
            </React.Fragment>}
          </div>
        </div>

        {isDebug && <div className={"window-container"}>
          <div className="thread-window">
            {threadIds && threadIds.map((threadId: string, index: number) =>
              <a key={index + 1} href="/" className={"switch-thread " + (currentThreadIndex === index ? 'selected' : '')} onClick={(ev) => this.switchThread(ev, index)}>
                <span>Thread {index + 1}</span>
                <span className="thread-id">{threadId}</span>
              </a>
            )}
            <a key={0} href="/" className={"new-thread"} onClick={(ev) => this.createAndSelectNewThread(ev)}>
              New Thread
            </a>
          </div>
          <div className="chat-window">
            <div className="chat-text">
              {chatItems.map((item: any, i: number) => 
                <div key={i}>
                  {/* <div className={"chat-bubble " + item.role} onClick={(ev) => this.handleTaskClick(ev)} dangerouslySetInnerHTML={{ __html: item.text }}></div> */}
                  <div className="chat-bubble-divider"></div><div className={"chat-bubble " + item.role} dangerouslySetInnerHTML={{ __html: item.text }}></div>
                </div>
              )}
              {!(chatItems && chatItems.length > 0) && !isLoading &&
                <div className="chat-no-entries">Try typing "hello. what do you do?"</div>
              }
              <div className={loadingClasses}>
                <img alt="Loading..." src={loader} />
              </div>
            </div>
            <div className="chat-input">
              <form onSubmit={(ev) => this.sendChat(ev)}>
                <input type="text" onChange={(ev) => this.updateChatText(ev)} value={chatText} placeholder="Enter text here..." />
              </form>
            </div>
          </div>

          <div className="tasks-window">
            {isLoadingTasks && 
              <div className="loader">
                <img alt="Loading..." src={loader} />
              </div>
            }
            {!isLoadingTasks &&
            <div className="task-lists">
              {taskLists && taskLists.map((taskList: any, i: number) => 
                <div key={i} className="task-list">
                  <div onClick={() => this.loadTasks(i)} className="task-list-title">
                    <span>{i === currentTaskList ? "-" : "+"}</span> <span>{taskList.title}</span>
                  </div>
                  <div className={i === currentTaskList ? "tasks selected" : "tasks"}>
                    {tasks[taskList.id] && tasks[taskList.id].map((task: any, index: number) => 
                      <div key={index}>{task.title}</div>
                    )}
                  </div>
                </div>
              )}
              {currentTaskList >= 0 && <div key={taskLists ? taskLists.length + 1 : 1}>
                <button className="primary" onClick={() => this.recommend()}>Recommend Tasks</button>
              </div>}
            </div>
            }
          </div>
        </div>}
      </div>
    );
  }
}

export default App;
